'use client'

import Script from 'next/script'
import { useEffect, useState } from 'react'

import { logError } from '@marketplace-web/shared/logging'
import { isStringTruthy } from '@marketplace-web/shared/utils'

import { getConsentKey, initConsentBanner, initOneTrust } from './utils'

function initCookieListModifications() {
  // Define target node that will be filled
  // by OneTrust eventually with the cookie list
  const targetNode = document.getElementById('ot-sdk-cookie-policy')

  // Don't do anything if the targetNode is not found
  if (!targetNode) return

  const observer = new MutationObserver(() => {
    // Disconnect the observer after the first mutation
    observer.disconnect()

    // Find the first table in the targetNode
    // Which will always be Strictly Necessary
    // Cookies table
    const table = targetNode.querySelector('table')

    // If there's no table, do nothing
    if (!table) return

    // Replace all links in with their innerHTML
    table.querySelectorAll('a').forEach(link => link.replaceWith(link.innerHTML))
  })

  observer.observe(targetNode, { childList: true })
}

function getHideConsent() {
  const { searchParams } = new URL(document.location.href)

  return isStringTruthy(searchParams.get('hide_consent'))
}

type Props = {
  host: string
  anonId?: string
  isWebview?: boolean
  userId?: string
}

// TODO: Add unit tests for this component
const ConsentBanner = ({ host, anonId, isWebview, userId }: Props) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    try {
      // TODO: After websplit, move it all to `./utils`
      initConsentBanner?.({ userId, isWebview, hideConsent: getHideConsent() })
    } catch (error) {
      logError(error)
    }
  }, [isWebview, userId])

  useEffect(() => {
    initOneTrust(!!userId, anonId)
    initCookieListModifications()
  }, [userId, anonId])

  useEffect(() => {
    // profile page with admin functionality renders main content in an iframe
    // which сauses the consent banner to be rendered twice
    setShow(!window.frameElement)
  }, [show])

  if (!show) return null

  return (
    <>
      {/* TODO: Separate into separate components */}
      <Script
        strategy="beforeInteractive"
        data-testid="tcfapi-stub"
        src="https://cdn.cookielaw.org/consent/tcf.stub.js"
      />
      <Script
        strategy="beforeInteractive"
        data-testid="ot-sdk-stub"
        data-document-language="true"
        data-domain-script={getConsentKey(host)}
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      />
    </>
  )
}

export default ConsentBanner
