import { useAbTest } from '@marketplace-web/shared/ab-tests'

const useBackButtonAbTest = () => {
  const holdout = useAbTest('buyer_domain_holdout_2025q1')?.variant !== 'off'
  const isBackButtonEnabled = useAbTest('back_button_search_web')?.variant === 'on' && holdout

  const historyMethod = {
    0: 'replaceState', // disabled
    1: 'pushState', // enabled
  }[Number(isBackButtonEnabled)] as 'pushState' | 'replaceState'

  return {
    historyMethod,
  }
}

export default useBackButtonAbTest
