'use client'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import {
  APP_LANDING_URL,
  BUSINESS_ACCOUNTS_GUIDE_URL,
  BUSINESS_ACCOUNTS_HOMEPAGE,
  HELP_CENTER_URL,
  HOW_IT_WORKS_URL,
  INFOBOARD_URL,
  ITEM_VERIFICATION_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from '_libs/common/event-tracker/events'

import { Screen } from 'constants/tracking/screens'

import LinkCell from '../LinkCell'
import LinksGroup from '../LinksGroup'

type Props = {
  isBusinessAccountLinksVisible: boolean
}

const DiscoverBlock = ({ isBusinessAccountLinksVisible }: Props) => {
  const translate = useTranslate('header.main_navigation.about')
  const { track } = useTracking()

  const isItemVerificationPageEnabled = useFeatureSwitch('web_item_verification_page_link')

  const handleHelpCenterClick = () => {
    track(
      clickEvent({
        target: ClickableElement.HelpCenter,
        screen: Screen.AboutMenu,
      }),
    )
  }

  return (
    <LinksGroup title={translate('discover.title')}>
      <LinkCell title={translate('discover.items.how_it_works')} url={HOW_IT_WORKS_URL} />
      {isItemVerificationPageEnabled && (
        <LinkCell
          title={translate('discover.items.item_verification')}
          url={ITEM_VERIFICATION_URL}
        />
      )}
      <LinkCell title={translate('discover.items.app')} url={APP_LANDING_URL} />
      <LinkCell
        title={translate('discover.items.help')}
        url={HELP_CENTER_URL}
        onClick={handleHelpCenterClick}
      />
      <LinkCell title={translate('discover.items.infoboard')} url={INFOBOARD_URL} />
      {isBusinessAccountLinksVisible && (
        <>
          <LinkCell title={translate('discover.items.pro')} url={BUSINESS_ACCOUNTS_HOMEPAGE} />
          <LinkCell
            title={translate('discover.items.pro_guide')}
            url={BUSINESS_ACCOUNTS_GUIDE_URL}
          />
        </>
      )}
    </LinksGroup>
  )
}

export default DiscoverBlock
