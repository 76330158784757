import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Screen } from 'constants/tracking/screens'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  name: Screen.Unknown,
}

const setName: CaseReducer<State, PayloadAction<{ name: Screen }>> = (draft, action) => {
  const { name } = action.payload

  draft.name = name
}

const screenSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setName,
  },
})

export const { actions } = screenSlice
export const plug = { [stateName]: screenSlice.reducer }
export default screenSlice.reducer
