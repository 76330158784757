'use client'

import { Controller, useFormContext } from 'react-hook-form'
import { Cell, Toggle, Text, Note, Spacer, Card } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { ConditionalWrapper } from '@marketplace-web/shared/ui-helpers'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

const Vacation = () => {
  const translate = useTranslate('account_settings.vacation')
  const { control } = useFormContext()
  const settingsBackgroundUpdateAbTest = useAbTest('settings_background_update_web')
  const isSettingsBackgroundUpdateAbTestEnabled = settingsBackgroundUpdateAbTest?.variant === 'on'

  const note = translate('note')

  const renderWrapper = (children: JSX.Element) => <Card>{children}</Card>

  return (
    <>
      <ConditionalWrapper
        wrapper={renderWrapper}
        condition={isSettingsBackgroundUpdateAbTestEnabled}
      >
        <Cell
          styling={Cell.Styling.Wide}
          title={
            <Text
              as="h2"
              type={Text.Type.Title}
              text={translate('title')}
              id="holiday-mode-toggle"
            />
          }
          suffix={
            <Controller
              name="isOnHoliday"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <Toggle
                  name={name}
                  checked={value}
                  onChange={onChange}
                  aria={{ 'aria-labelledby': 'holiday-mode-toggle' }}
                />
              )}
            />
          }
        />
      </ConditionalWrapper>
      {note ? <Note text={translate('note')} /> : <Spacer size={Spacer.Size.Large} />}
    </>
  )
}

export default Vacation
