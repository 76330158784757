'use client'

import { ChevronRight16, Dots24 } from '@vinted/monochrome-icons'
import { Cell, Image, Divider, Icon, Badge, Text, Spacer } from '@vinted/web-ui'
import { MouseEvent, useEffect, useMemo, useState } from 'react'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { CatalogNavigationDto } from 'types/dtos/catalog'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { selectCatalogEvent } from '_libs/common/event-tracker/events'
import { CatalogAttribute } from 'constants/catalog'

type Props = {
  category: CatalogNavigationDto
}

const ALL_L1_ID = -1
const ALL_L2_ID = -2
const CLOSED_ID = 0

const NavigatedCategory = ({ category }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate()

  const [selectedL1Id, setSelectedL1Id] = useState(CLOSED_ID)
  const [selectedL2Id, setSelectedL2Id] = useState(CLOSED_ID)

  // reset state after tab navigation
  useEffect(() => {
    setSelectedL1Id(CLOSED_ID)
    setSelectedL2Id(CLOSED_ID)
  }, [category])

  const selectedL1 = useMemo(() => {
    if (selectedL1Id === ALL_L1_ID) return undefined

    return category.catalogs.find(item => item.id === selectedL1Id) || category.catalogs[0]
  }, [category.catalogs, selectedL1Id])

  const allText = translate('header.main_navigation.catalogs.see_all')

  const renderText = (selected: boolean, text: string) => (
    <Text as="span" bold={selected} theme={selected ? 'amplified' : 'inherit'} text={text} />
  )

  const trackSelectCatalog = (catalog: CatalogNavigationDto) => {
    track(
      selectCatalogEvent({
        catalogIds: [catalog.id],
        attributeId: CatalogAttribute.Navigation,
      }),
    )
  }

  const handleClickL2 = (catalog: CatalogNavigationDto) => {
    trackSelectCatalog(catalog)
  }

  const handleClickL1 = (event: MouseEvent, catalog: CatalogNavigationDto) => {
    const pointerEvent = event.nativeEvent as PointerEvent
    if (
      // pointerType: "" means keyboard (a11y)
      (pointerEvent.pointerType === '' || pointerEvent.pointerType === 'touch') &&
      catalog.catalogs.length > 0
    ) {
      event.preventDefault()
    } else {
      trackSelectCatalog(catalog)
    }

    setSelectedL1Id(catalog.id)
  }

  const handleClickAllL1 = () => {
    trackSelectCatalog(category)
  }

  const handleMouseEnterLevel1 = (id: number) => () => {
    setSelectedL1Id(id)
  }

  const handleMouseEnterLevel2 = (id: number) => () => {
    setSelectedL2Id(id)
  }

  return (
    <div className="catalog-dropdown-rework__navigated-container">
      <div className="catalog-dropdown-rework__level1-container">
        <div onMouseEnter={handleMouseEnterLevel1(ALL_L1_ID)}>
          <Cell
            testId="category-l1-all"
            url={category.custom_url || category.url}
            onClick={handleClickAllL1}
            type={Cell.Type.Navigating}
            prefix={<Icon name={Dots24} color={Icon.Color.Primary} />}
            body={renderText(selectedL1Id === ALL_L1_ID, allText)}
            styling={Cell.Styling.Narrow}
          />
        </div>

        {category.catalogs.map(child => (
          <div key={child.id} onMouseEnter={handleMouseEnterLevel1(child.id)}>
            <Cell
              url={child.custom_url || child.url}
              onClick={ev => handleClickL1(ev, child)}
              type={Cell.Type.Navigating}
              prefix={
                <Image
                  src={child.photo?.url}
                  scaling={Image.Scaling.Cover}
                  size={Image.Size.Regular}
                />
              }
              suffix={(() => {
                if (child.badge) {
                  return (
                    <Badge theme={child.badge.theme || 'primary'} content={child.badge.title} />
                  )
                }

                if (child.id === selectedL1?.id && child.catalogs.length > 0) {
                  return (
                    <div className="u-fill-height u-fill-width u-flexbox u-align-items-center u-justify-content-center">
                      <Icon name={ChevronRight16} testId={`chevron-${child.title}`} />
                    </div>
                  )
                }

                // prevent breaking line into 2 lines when hovering and chevron appears
                return <Spacer size={Spacer.Size.Large} />
              })()}
              body={renderText(child.id === selectedL1?.id, child.title)}
              styling={Cell.Styling.Narrow}
              aria={{ 'aria-label': child.title }}
              urlProps={{ title: child.title }}
            />
          </div>
        ))}
      </div>

      <Divider orientation={Divider.Orientation.Vertical} />

      <div className="u-flex-1 u-fill-height u-sticky u-top">
        <div
          className="catalog-dropdown-rework__level2-container"
          onMouseLeave={handleMouseEnterLevel2(CLOSED_ID)}
        >
          {selectedL1 && selectedL1.catalogs.length > 0 && (
            <>
              <div onMouseEnter={handleMouseEnterLevel2(ALL_L2_ID)}>
                <Cell
                  onClick={() => handleClickL2(selectedL1)}
                  url={selectedL1.custom_url || selectedL1.url}
                  body={renderText(selectedL2Id === ALL_L2_ID, allText)}
                  styling={Cell.Styling.Narrow}
                  type={Cell.Type.Navigating}
                />
              </div>

              {selectedL1.catalogs.map(child => (
                <div key={child.id} onMouseEnter={handleMouseEnterLevel2(child.id)}>
                  <Cell
                    url={child.custom_url || child.url}
                    onClick={() => handleClickL2(child)}
                    body={renderText(selectedL2Id === child.id, child.title)}
                    styling={Cell.Styling.Narrow}
                    type={Cell.Type.Navigating}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavigatedCategory
