'use client'

import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation'
import { ReactNode, useMemo, useState } from 'react'

import { PageId } from '../types/page-id'
import { toNextjsParams } from '../utils/url'
import RequestContext from './RequestContext'

type Props = {
  children: ReactNode
  pageId: PageId | null
  url: string
  userAgent: string
}

/** Note that url related fields are not available server side.
 * Next.js doesn't provide a proper way of getting the current URL server-side
 * and, as of now, we don't have use cases that cannot be solved without relying on URL from the provider (see https://nextjs.org/docs/app/api-reference/file-conventions/page#props)
 * For more context, see https://github.com/vercel/next.js/issues/43704
 */
const RequestProvider = ({ pageId, children, userAgent, url }: Props) => {
  // Value of this state isn't needed a goal is only to trigger rerender
  // eslint-disable-next-line react/hook-use-state
  const [, setRefresh] = useState(false)
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const params = useParams()
  const { push: routerPush } = useRouter()

  const value = useMemo(() => {
    const urlObject = new URL(url)
    if (pathname) urlObject.pathname = pathname
    if (searchParams) urlObject.search = searchParams.toString()

    return {
      pageId,
      userAgent,
      location: {
        url: urlObject.toString(),
        serverUrl: url,
        routerPush,
        push: (href: string) => window.history.pushState({}, '', href),
        replace: (href: string) => window.history.replaceState({}, '', href),
        refreshUrl: () => setRefresh(prevState => !prevState),
        urlParams: { ...params, ...toNextjsParams(urlObject.searchParams) },
        back: () => window.history.back(),
      },
    }
  }, [pageId, url, pathname, routerPush, params, userAgent, searchParams])

  return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
}

export default RequestProvider
