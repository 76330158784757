import { useContext, useEffect, useRef } from 'react'

import { UserStatsContext, UserStatsContextValue } from '../../contexts/UserStatsProvider'

const useUserStats = (): UserStatsContextValue => {
  const isInitialFetchingInitiated = useRef(false)
  const userStatsContext = useContext(UserStatsContext)

  useEffect(() => {
    if (userStatsContext.userStats) return
    if (isInitialFetchingInitiated.current) return

    isInitialFetchingInitiated.current = true
    userStatsContext.fetchUserStats()
  }, [userStatsContext])

  return userStatsContext
}

export default useUserStats
