import { CatalogNavigationDto } from 'types/dtos/catalog'
import { LanguageDto } from 'types/dtos/language'

import SideNavigation from '../SideNavigation'
import CatalogNavigation from '../CatalogNavigation'

type Props = {
  languages: Array<LanguageDto>
  catalogTree: Array<CatalogNavigationDto>
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
  isOurPlatformVisible: boolean
  isSideNavigationOpen: boolean
}

const HeaderCatalogNavigation = ({
  isSideNavigationOpen,
  languages,
  catalogTree,
  impressumUrl,
  isBusinessAccountLinksVisible,
  isOurPlatformVisible,
}: Props) => {
  return (
    <div className="l-header__navigation">
      {isSideNavigationOpen && (
        <div className="u-mobiles-only">
          <SideNavigation
            catalogTree={catalogTree}
            impressumUrl={impressumUrl}
            isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
            initialLanguages={languages}
          />
        </div>
      )}

      <div className="u-desktops-only">
        <div className="container">
          <CatalogNavigation
            tree={catalogTree}
            impressumUrl={impressumUrl}
            isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
            isOurPlatformVisible={isOurPlatformVisible}
          />
        </div>
      </div>
    </div>
  )
}

export default HeaderCatalogNavigation
