'use client'

import {
  AddMember24,
  Donate24,
  Filters24,
  Logout24,
  Receipt24,
  Settings24,
  Wallet24,
} from '@vinted/monochrome-icons'
import { Badge, Cell, Icon, Image, Spacer } from '@vinted/web-ui'

import { AccountSwitch } from '@marketplace-web/domain/account-staff'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { useCookie } from '@marketplace-web/shared/cookies'
import { useShouldShowDarkModeBanner } from '@marketplace-web/shared/dark-mode'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { EMPTY_USER_IMAGE_NAME, useAsset } from '@marketplace-web/shared/ui-helpers'
import { findThumbnail } from '@marketplace-web/shared/utils'

import { clickEvent } from '_libs/common/event-tracker/events'
import { OrderType } from 'constants/my-orders'
import {
  DONATIONS_SETTINGS_URL,
  GO_TO_WALLET_URL,
  MEMBER_PROFILE_URL,
  MY_ORDERS_URL,
  PROFILE_SETTINGS_URL,
  REFERRALS_URL,
  ROOT_URL,
  USER_PERSONALIZATION_SIZES_URL,
  WALLET_INVOICES_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { logoutUser } from 'data/api/authentication/requests'

import CurrentWalletAmount from './CurrentWalletAmount'
import UserMenuDropdown from './UserMenuDropdown'
import UserMenuGroup from './UserMenuGroup'

enum MenuItem {
  Profile = 'Profile',
  Settings = 'Settings',
  Customize = 'Customize',
  Balance = 'Balance',
  Orders = 'Orders',
  EnterDonationFromSettings = 'EnterDonationFromSettings',
  Referrals = 'Referrals',
  Invoices = 'Invoices',
  Logout = 'Logout',
}

const UserMenu = () => {
  const { user } = useSession()
  const translate = useTranslate()
  const { track } = useTracking()
  const cookies = useCookie()
  const asset = useAsset('assets/no-photo')

  const isPaymentsEnabled = useFeatureSwitch('payments')
  const shouldShowMyOrdersNewBadge = useFeatureSwitch('my_orders_new_badge_web')
  const showFundraiser = !user?.business
  const isReferralsEnabled = useFeatureSwitch('referrals')
  const shouldShowDarkModeAdoption = useShouldShowDarkModeBanner()
  const isImpersonatingBusiness = user?.impersonating_business
  const myOrdersDynamicDefaultTabAbTest = useAbTest('my_orders_dynamic_default_tab_v2')
  const userMenuDropdownAbTest = useAbTest('user_menu_dropdown_reorder_web')
  useTrackAbTest(userMenuDropdownAbTest)

  const isUserMenuDropdOwnAbTestOn =
    userMenuDropdownAbTest && userMenuDropdownAbTest.variant !== 'off'

  if (!user) return null

  const userPhoto = findThumbnail(user.photo) || asset(EMPTY_USER_IMAGE_NAME)

  const handleLogoutClick = async () => {
    const response = await logoutUser({ cookies })

    if (response) navigateToPage(ROOT_URL)
  }

  const handleTrackOnClick = (item: MenuItem) =>
    track(
      clickEvent({
        target: ClickableElement[MenuItem[item]],
        screen: Screen.UserMenu,
      }),
    )

  const renderActionTitle = (action: string, showNewBadge?: boolean) => {
    return (
      <>
        {action}
        {showNewBadge && (
          <>
            <Spacer orientation={Spacer.Orientation.Vertical} />
            <Badge theme="primary" content={translate('common.new_badge')} />
          </>
        )}
      </>
    )
  }

  const renderActionWithIcon = (
    action: string,
    icon: ComponentProps<typeof Icon>['name'],
    showNewBadge?: boolean,
  ) => {
    return isUserMenuDropdOwnAbTestOn ? (
      <div className="u-padding-top-regular u-padding-bottom-regular">
        <Cell
          styling={Cell.Styling.Tight}
          prefix={<Icon name={icon} color={Icon.Color.GreyscaleLevel2} />}
          title={renderActionTitle(action, showNewBadge)}
          theme="transparent"
        />
      </div>
    ) : (
      renderActionTitle(action, showNewBadge)
    )
  }

  const renderWalletAction = () => (
    <UserMenuGroup.Action
      href={GO_TO_WALLET_URL}
      onClick={() => handleTrackOnClick(MenuItem.Balance)}
    >
      <span className="u-flexbox u-justify-content-between u-align-items-center">
        {renderActionWithIcon(translate('header.user_menu.account.items.wallet.title'), Wallet24)}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.X5Large} />
        <span className="u-ui-padding-left-regular">
          <CurrentWalletAmount />
        </span>
      </span>
    </UserMenuGroup.Action>
  )

  const renderMyOrdersAction = () => (
    <UserMenuGroup.Action
      href={MY_ORDERS_URL({
        type: myOrdersDynamicDefaultTabAbTest?.variant === 'on' ? '' : OrderType.Sold,
        isDynamicTabMenuLink: !!myOrdersDynamicDefaultTabAbTest?.variant,
      })}
      onClick={() => handleTrackOnClick(MenuItem.Orders)}
    >
      {renderActionWithIcon(
        translate('header.user_menu.account.items.my_orders.title'),
        Receipt24,
        shouldShowMyOrdersNewBadge,
      )}
    </UserMenuGroup.Action>
  )

  const renderFundraiserAction = () => (
    <UserMenuGroup.Action
      href={DONATIONS_SETTINGS_URL}
      onClick={() => handleTrackOnClick(MenuItem.EnterDonationFromSettings)}
    >
      {renderActionWithIcon(translate('header.user_menu.account.items.donations.title'), Donate24)}
    </UserMenuGroup.Action>
  )

  const renderLogoutAction = () => (
    <UserMenuGroup.Action onClick={handleLogoutClick}>
      <span className="u-color-red">
        {renderActionWithIcon(translate('header.user_menu.account.items.logout.title'), Logout24)}
      </span>
    </UserMenuGroup.Action>
  )

  const renderUserMenuDropdownExperiment = () => {
    return (
      <UserMenuGroup.Item>
        <UserMenuGroup.Action
          href={MEMBER_PROFILE_URL(user.id)}
          onClick={() => handleTrackOnClick(MenuItem.Profile)}
        >
          <Cell
            title={user.login}
            body={translate('header.user_menu.account.view_profile')}
            styling={Cell.Styling.Tight}
            theme="transparent"
            prefix={
              <Image
                src={user.photo?.full_size_url}
                size={Image.Size.Large}
                styling={Image.Styling.Circle}
                alt={translate('common.a11y.alt.user_photo')}
              />
            }
          />
          <Spacer size={Spacer.Size.Medium} />
        </UserMenuGroup.Action>
        {isPaymentsEnabled && !isImpersonatingBusiness && renderWalletAction()}
        {renderMyOrdersAction()}
        {!isImpersonatingBusiness && (
          <UserMenuGroup.Action
            href={USER_PERSONALIZATION_SIZES_URL}
            onClick={() => handleTrackOnClick(MenuItem.Customize)}
          >
            {renderActionWithIcon(
              translate('header.user_menu.account.items.personalization.title'),
              Filters24,
            )}
          </UserMenuGroup.Action>
        )}
        {showFundraiser && !isImpersonatingBusiness && renderFundraiserAction()}
        {!isPaymentsEnabled && !isImpersonatingBusiness && (
          <UserMenuGroup.Action
            href={WALLET_INVOICES_URL}
            onClick={() => handleTrackOnClick(MenuItem.Invoices)}
          >
            {renderActionWithIcon(
              translate('header.user_menu.account.items.invoices.title'),
              Receipt24,
            )}
          </UserMenuGroup.Action>
        )}
        {isReferralsEnabled && !isImpersonatingBusiness && (
          <UserMenuGroup.Action
            href={REFERRALS_URL}
            onClick={() => handleTrackOnClick(MenuItem.Referrals)}
          >
            {renderActionWithIcon(
              translate('header.user_menu.account.items.referrals.title'),
              AddMember24,
            )}
          </UserMenuGroup.Action>
        )}
        {!isImpersonatingBusiness && (
          <UserMenuGroup.Action
            href={PROFILE_SETTINGS_URL}
            onClick={() => handleTrackOnClick(MenuItem.Settings)}
          >
            {renderActionWithIcon(
              translate('header.user_menu.account.items.settings.title'),
              Settings24,
            )}
            {shouldShowDarkModeAdoption && (
              <>
                <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
                <Badge theme="primary" content={translate('common.new_badge')} />
              </>
            )}
          </UserMenuGroup.Action>
        )}
        <AccountSwitch />
        {renderLogoutAction()}
      </UserMenuGroup.Item>
    )
  }

  return (
    <UserMenuDropdown userName={user.login} userPhoto={userPhoto}>
      <UserMenuGroup>
        {isUserMenuDropdOwnAbTestOn ? (
          renderUserMenuDropdownExperiment()
        ) : (
          <UserMenuGroup.Item>
            <UserMenuGroup.Action
              href={MEMBER_PROFILE_URL(user.id)}
              onClick={() => handleTrackOnClick(MenuItem.Profile)}
            >
              {translate('header.user_menu.account.items.profile.title')}
            </UserMenuGroup.Action>
            {!isImpersonatingBusiness && (
              <UserMenuGroup.Action
                href={PROFILE_SETTINGS_URL}
                onClick={() => handleTrackOnClick(MenuItem.Settings)}
              >
                {translate('header.user_menu.account.items.settings.title')}
                {shouldShowDarkModeAdoption && (
                  <>
                    <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
                    <Badge theme="primary" content={translate('common.new_badge')} />
                  </>
                )}
              </UserMenuGroup.Action>
            )}
            {!isImpersonatingBusiness && (
              <UserMenuGroup.Action
                href={USER_PERSONALIZATION_SIZES_URL}
                onClick={() => handleTrackOnClick(MenuItem.Customize)}
              >
                {translate('header.user_menu.account.items.personalization.title')}
              </UserMenuGroup.Action>
            )}
            {isPaymentsEnabled && !isImpersonatingBusiness && renderWalletAction()}
            {renderMyOrdersAction()}
            {showFundraiser && !isImpersonatingBusiness && renderFundraiserAction()}
            {!isPaymentsEnabled && !isImpersonatingBusiness && (
              <UserMenuGroup.Action
                href={WALLET_INVOICES_URL}
                onClick={() => handleTrackOnClick(MenuItem.Invoices)}
              >
                {translate('header.user_menu.account.items.invoices.title')}
              </UserMenuGroup.Action>
            )}
            {isReferralsEnabled && !isImpersonatingBusiness && (
              <UserMenuGroup.Action
                href={REFERRALS_URL}
                onClick={() => handleTrackOnClick(MenuItem.Referrals)}
              >
                {translate('header.user_menu.account.items.referrals.title')}
              </UserMenuGroup.Action>
            )}
            <AccountSwitch />
            {renderLogoutAction()}
          </UserMenuGroup.Item>
        )}
      </UserMenuGroup>
    </UserMenuDropdown>
  )
}

export default UserMenu
