export const ROOT_CATALOG_ID = -1 as const
export const ROOT_CATALOG_ICON_NAME = 'dots'
export const WEB_CATALOG_ROOT_ALL_CODE = '_ALL'
export const ABOUT_TAB_ID = 'about-tab-id'
export const OUR_PLATFORM_ID = 'our-platform-id'
export const NAVIGATION_COOKIES_URL = '#'
export const CATALOG_SHRINK_THRESHOLD = 8

export enum CatalogPhotoThumbnail {
  Thumb24 = 'thumb24',
  Thumb48 = 'thumb48',
  Thumb70 = 'thumb70',
  Thumb150 = 'thumb150',
}

export enum CatalogAttribute {
  // 1 is controlled from legacy views
  Navigation = 1,
  Breadcrumb = 2,
  Filter = 3,
  Subcatalog = 4,
  Home = 5,
  StyleFilter = 6,
  ProfileFilters = 7,
}

export enum CatalogFrom {
  RecentPurchases = 0,
  IvsBlock = 3,
}

export const STYLE_DEPTH = 2

export enum CatalogCode {
  WomenRoot = 'WOMEN_ROOT',
  Mens = 'MENS',
  ChildrenNew = 'CHILDREN_NEW',
  PetCare = 'PET_CARE',
  Home = 'HOME',
  Entertainment = 'ENTERTAINMENT',
}
