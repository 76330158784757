import { transformBundleDiscountsConfigurationDto } from 'data/transformers/bundle-discount'

import { SystemConfigurationDto, SystemConfigurationModel } from '../types/system-configuration'

export const transformSystemConfigurationDto = ({
  measurements,
  transparency_law_translation_keys,
  urls,
  user_country,
  business_account_informational_links_visible,
  phone_number_prefix,
  multitier_bundle_discount_config,
  registration_real_name_required,
  newsletter_subscription_type,
  portal,
  cookie_consent_version,
  websocket_url,
  number_of_images_per_item,
  crm,
  welcome_images,
  url_regex,
}: SystemConfigurationDto): SystemConfigurationModel => ({
  measurements,
  transparencyLawKeys: {
    buyerRights: transparency_law_translation_keys.buyer_rights,
    professionalSeller: transparency_law_translation_keys.professional_seller,
  },
  urls: {
    impressum: urls.impressum,
    buyerProtection: urls.buyer_protection,
    ourPlatform: urls.our_platform,
    relevancyDescription: urls.relevancy_description,
    feedbackDescription: urls.feedback_description,
    buyerRights: urls.buyer_rights,
    civilCode: urls.civil_code,
    lawOfObligations: urls.law_of_obligations,
    professionalSeller: urls.professional_seller,
    onlineComplaints: urls.online_complaints,
  },
  userCountry: user_country,
  businessAccountInformationalLinksVisible: business_account_informational_links_visible,
  newsletterSubscriptionType: newsletter_subscription_type,
  phoneNumberPrefix: phone_number_prefix,
  bundleDiscountsConfiguration: transformBundleDiscountsConfigurationDto(
    multitier_bundle_discount_config,
  ),
  registrationRealNameRequired: registration_real_name_required,
  portal,
  cookieConsentVersion: cookie_consent_version,
  websocketUrl: websocket_url,
  numberOfImagesPerItem: number_of_images_per_item,
  crm: {
    brazeConfig: crm.braze_config && {
      sdkEndpoint: crm.braze_config.sdk_endpoint,
      sdkKey: crm.braze_config.sdk_key,
      apiKey: crm.braze_config.api_key,
      safariWebsitePushId: crm.braze_config.safari_push_id,
    },
  },
  welcomeImages: {
    phone: welcome_images.web.phone,
    tabletsUp: welcome_images.web.tablets_up,
    wide: welcome_images.web.wide,
  },
  urlRegex: url_regex,
})
