'use client'

import { useMemo } from 'react'

import CompanyBlock from '../../CatalogNavigation/AboutPanel/CompanyBlock'
import DiscoverBlock from '../../CatalogNavigation/AboutPanel/DiscoverBlock'
import { LinkCellContext } from '../../CatalogNavigation/AboutPanel/LinkCell/LinkCell'
import PoliciesBlock from '../../CatalogNavigation/AboutPanel/PoliciesBlock'
import PrivacyBlock from '../../CatalogNavigation/AboutPanel/PrivacyBlock'

type Props = {
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
}

const AboutTabContent = ({ impressumUrl, isBusinessAccountLinksVisible }: Props) => {
  const linkCellContextValue = useMemo(() => ({ boldHover: true }), [])

  return (
    <LinkCellContext.Provider value={linkCellContextValue}>
      <div className="catalog-dropdown-rework__navigated-container u-flex-wrap">
        <div className="catalog-dropdown-rework__level1-container">
          <DiscoverBlock isBusinessAccountLinksVisible={isBusinessAccountLinksVisible} />
        </div>
        <div className="catalog-dropdown-rework__level1-container">
          <CompanyBlock />
        </div>
        <div className="catalog-dropdown-rework__level1-container">
          <PoliciesBlock
            impressumUrl={impressumUrl}
            isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
          />
        </div>
        <div className="catalog-dropdown-rework__level1-container">
          <PrivacyBlock />
        </div>
      </div>
    </LinkCellContext.Provider>
  )
}

export default AboutTabContent
