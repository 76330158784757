'use client'

import { Button } from '@vinted/web-ui'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { toUrlQuery } from '@marketplace-web/shared/utils'

import { clickEvent } from '_libs/common/event-tracker/events'
import { ITEM_UPLOAD_URL, SIGNUP_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'

const HeaderUploadItem = () => {
  const { user } = useSession()
  const userId = user?.id

  const translate = useTranslate()
  const { track } = useTracking()

  function handleAnchorClick() {
    track(clickEvent({ target: ClickableElement.UploadItem }))
  }

  return (
    <Button
      text={translate('header.actions.upload_item')}
      styling={Button.Styling.Filled}
      size={Button.Size.Small}
      url={userId ? ITEM_UPLOAD_URL : `${SIGNUP_URL}?${toUrlQuery({ ref_url: ITEM_UPLOAD_URL })}`}
      rel="nofollow"
      onClick={handleAnchorClick}
    />
  )
}

export default HeaderUploadItem
