import { useEffect } from 'react'

import { useLocation } from '@marketplace-web/shared/browser'
import { cookiesDataByName, useCookie } from '@marketplace-web/shared/cookies'
import { UiState } from '@marketplace-web/shared/ui-helpers'
import { WHITELISTING_RULES } from '_libs/common/braze/constants'
import type { InAppMessageProvider } from '_libs/common/braze/providers'

const isPathWhitelisted = (path: string) =>
  WHITELISTING_RULES.some(rule => new RegExp(rule).test(path))

export default function useManageInAppMessage(
  inAppMessageProvider: InAppMessageProvider | undefined,
) {
  const { relativeUrl, urlParams, searchParams } = useLocation()
  const cookies = useCookie()

  const isShowingOnboardingModal = urlParams.onboarding_modal_status === 'shown'
  const couldOnboardingModalBeShown =
    cookies.get(cookiesDataByName.banners_ui_state) === UiState.Pending
  const isProfilePromoShown = !!searchParams.promo_shown

  const isAnyModalShown =
    isShowingOnboardingModal || couldOnboardingModalBeShown || isProfilePromoShown
  const shouldEnable = isPathWhitelisted(relativeUrl) && !isAnyModalShown

  useEffect(() => {
    if (!inAppMessageProvider) return

    if (shouldEnable) {
      inAppMessageProvider.enable()
      inAppMessageProvider.publishStoredMessages()
    } else {
      inAppMessageProvider.disable()
    }
  }, [inAppMessageProvider, shouldEnable])
}
