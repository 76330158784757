'use client'

import { Spacer } from '@vinted/web-ui'

import PoliciesBlock from '../PoliciesBlock'
import PrivacyBlock from '../PrivacyBlock'

type Props = {
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
}

const SecondColumn = ({ impressumUrl, isBusinessAccountLinksVisible }: Props) => {
  return (
    <div>
      <PoliciesBlock
        impressumUrl={impressumUrl}
        isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
      />
      <Spacer size={Spacer.Size.X2Large} />
      <PrivacyBlock />
    </div>
  )
}

export default SecondColumn
