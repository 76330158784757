import {
  BundleDiscountDto,
  UserBundleDiscountDto,
  BundleDiscountsConfigurationDto,
} from 'types/dtos/bundle-discount'
import {
  BundleDiscountModel,
  UserBundleDiscountModel,
  BundleDiscountsConfigurationModel,
} from 'types/models/bundle-discount'

const transformDiscountDto = ({
  fraction,
  minimal_item_count,
}: BundleDiscountDto): BundleDiscountModel => ({
  fraction,
  minimalItemCount: minimal_item_count,
})

export const transformDiscountDtos = (dtos: Array<BundleDiscountDto>): Array<BundleDiscountModel> =>
  dtos.map(transformDiscountDto)

export const transformUserBundleDiscountDto = (
  dto: UserBundleDiscountDto,
): UserBundleDiscountModel => ({
  id: dto.id,
  userId: dto.user_id,
  isEnabled: dto.enabled,
  minimalItemCount: dto.minimal_item_count,
  fraction: dto.fraction,
  discounts: transformDiscountDtos(dto.discounts),
})

export const transformBundleDiscountsConfigurationDto = ({
  discounts,
  default_discounts,
}: BundleDiscountsConfigurationDto): BundleDiscountsConfigurationModel => ({
  discountFractions: discounts,
  defaultDiscounts: transformDiscountDtos(default_discounts),
})
