'use client'

import { MouseEvent } from 'react'

import { useConsent, toggleOneTrustInfoDisplay } from '@marketplace-web/shared/consent'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { NAVIGATION_COOKIES_URL } from 'constants/catalog'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { clickEvent } from '_libs/common/event-tracker/events'

import LinkCell from '../LinkCell'
import LinksGroup from '../LinksGroup'

const PrivacyBlock = () => {
  const translate = useTranslate('header.main_navigation.about')
  const { track } = useTracking()

  const { isCookieConsentVersion } = useConsent()

  const handleCookiesClick = (event: MouseEvent) => {
    event.preventDefault()
    toggleOneTrustInfoDisplay()

    track(
      clickEvent({
        target: ClickableElement.CookieSettings,
        screen: Screen.AboutMenu,
      }),
    )
  }

  return (
    isCookieConsentVersion && (
      <LinksGroup title={translate('privacy.title')}>
        <LinkCell
          title={translate('privacy.items.cookies')}
          url={NAVIGATION_COOKIES_URL}
          onClick={handleCookiesClick}
          testId="about-panel-cookie-settings-button"
        />
      </LinksGroup>
    )
  )
}

export default PrivacyBlock
