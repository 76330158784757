/* eslint no-restricted-globals:0 */

'use client'

import { Component, ReactNode } from 'react'

import { globalTracker } from '../utils/global-tracker'

import TrackingContext from './TrackingContext'

type Props = {
  children?: ReactNode
}

// TODO: creat tracker locally after redux cleanup
class TrackingProvider extends Component<Props> {
  tracker = globalTracker

  contextValues = {
    tracker: this.tracker,
  }

  render() {
    return (
      <TrackingContext.Provider value={this.contextValues}>
        {this.props.children}
      </TrackingContext.Provider>
    )
  }
}

export default TrackingProvider
