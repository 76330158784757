'use client'

import { Spacer } from '@vinted/web-ui'

import DiscoverBlock from '../DiscoverBlock'
import CompanyBlock from '../CompanyBlock'

type Props = {
  isBusinessAccountLinksVisible: boolean
}

const FirstColumn = ({ isBusinessAccountLinksVisible }: Props) => {
  return (
    <div>
      <DiscoverBlock isBusinessAccountLinksVisible={isBusinessAccountLinksVisible} />
      <Spacer size={Spacer.Size.X2Large} />
      <CompanyBlock />
    </div>
  )
}

export default FirstColumn
