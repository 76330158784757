'use client'

import { MouseEvent } from 'react'
import { Button } from '@vinted/web-ui'

import { useSession } from '@marketplace-web/shared/session'
import HeaderLogo from 'components/HeaderLogo'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { logoutUser } from 'data/api/authentication/requests'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { ROOT_URL } from 'constants/routes'
import { useCookie } from '@marketplace-web/shared/cookies'

import { useEmailCodeTest } from './hooks/useEmailCodeTest'

type Props = {
  children?: JSX.Element
}

const HeaderNoNavigation = ({ children }: Props) => {
  const { user } = useSession()
  const translate = useTranslate()
  const {
    trackLogoutEvent,
    isLoadingPrompts,
    trackEmailCodeSkipEvent,
    handleDismissVerificationPrompt,
  } = useEmailCodeTest()
  const cookies = useCookie()

  const handleLogoutClick = async () => {
    const response = await logoutUser({ cookies })

    if (response) {
      trackLogoutEvent()
      navigateToPage(ROOT_URL)
    }
  }

  const handleDismissPrompt = async () => {
    await handleDismissVerificationPrompt()

    navigateToPage(ROOT_URL)
  }

  const handleLogoClick = (event: MouseEvent) => {
    event.preventDefault()

    trackEmailCodeSkipEvent()
    handleDismissPrompt()
  }

  const renderLogoutButton = () => {
    if (!user) return null

    return (
      <div className="u-flexbox u-margin-left-auto u-align-items-center u-position-relative u-z-index-notification">
        <Button
          theme="muted"
          styling={Button.Styling.Flat}
          size={Button.Size.Medium}
          onClick={handleLogoutClick}
          disabled={isLoadingPrompts}
        >
          {translate('header.actions.log_out')}
        </Button>
      </div>
    )
  }

  return (
    <div className="l-header">
      <header className="l-header__main">
        <div className="container u-flexbox u-align-items-center u-fill-height">
          <div className="u-flexbox u-align-items-center u-fill-width">
            <HeaderLogo onClick={handleLogoClick} />
            {renderLogoutButton()}
            {children}
          </div>
        </div>
      </header>
    </div>
  )
}

export default HeaderNoNavigation
