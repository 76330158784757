import {
  Action,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Dispatch,
  Middleware,
  Reducer,
  Store,
  StoreEnhancer,
} from '@reduxjs/toolkit'
import { datadogRum } from '@datadog/browser-rum'
import createSagaMiddleware from 'redux-saga'
import type { SagaMiddlewareOptions } from 'redux-saga'
import { all } from 'redux-saga/effects'

import { logError } from '@marketplace-web/shared/logging'
import { serverSide } from '@marketplace-web/shared/environment'

import { staticReducers } from './reducers'
import { getStaticSagas } from './sagas'
import { Reducers, Sagas } from './types'

export function createReducers(reducers?: Reducers) {
  return combineReducers({
    ...staticReducers,
    ...reducers,
  })
}

const crashReporter = () => (next: Dispatch<Action>) => (action: Action) => {
  try {
    return next(action)
  } catch (err) {
    logError(err, {
      extra: typeof action.type === 'string' ? action.type : 'unknown',
    })

    throw err
  }
}

const handleSagaError: SagaMiddlewareOptions['onError'] = (error, errorInfo) => {
  if (!serverSide) {
    datadogRum.addError(error, { saga: true })
  }

  logError(error, { extra: `redux-saga: ${errorInfo.sagaStack}` })
}

export function configureStore(initialState: any, reducer: Reducer, sagas: Sagas = []) {
  let enhancer: StoreEnhancer
  const middleware: Array<Middleware> = []
  const sagaMiddleware = createSagaMiddleware({ onError: handleSagaError })
  const development = process.env.NODE_ENV === 'development'

  middleware.push(sagaMiddleware)

  if (development) {
    const reduxDevTools =
      !serverSide &&
      // Airbnb config allows only window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      /* eslint-disable no-underscore-dangle */
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    /* eslint-enable */

    enhancer = compose(applyMiddleware(...middleware), reduxDevTools || compose)
  } else {
    enhancer = applyMiddleware(...middleware, crashReporter)
  }

  const store: Store = createStore(reducer, initialState, enhancer)

  const sagaRun = sagaMiddleware.run(function* rootSaga() {
    yield all([...getStaticSagas(), ...sagas])
  })

  return { store, sagaRun }
}
