import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useSession } from '@marketplace-web/shared/session'

const useIsSearchByImageEnabled = () => {
  const fs = useFeatureSwitch('search_by_image_mvp_internal')
  const { user } = useSession()

  return fs || user?.email?.endsWith('@vinted.com')
}

export default useIsSearchByImageEnabled
