'use client'

import { MouseEvent, useState } from 'react'
import { Badge, Card, Cell, Icon, Image, Text } from '@vinted/web-ui'
import { Dots24 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'

import {
  CATALOG_SHRINK_THRESHOLD,
  ROOT_CATALOG_ID,
  WEB_CATALOG_ROOT_ALL_CODE,
} from 'constants/catalog'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { CatalogNavigationModel } from 'types/models/catalog'

import CatalogsPanel from '../CatalogsPanel'

type Props = {
  rootCategory: CatalogNavigationModel
}

const CategoriesPanel = ({ rootCategory }: Props) => {
  const translate = useTranslate()
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | number>(
    rootCategory.catalogs[0]?.id || ROOT_CATALOG_ID,
  )

  const parentCatalog = {
    ...rootCategory,
    badge: null,
    code: WEB_CATALOG_ROOT_ALL_CODE,
    title: translate('header.main_navigation.catalogs.see_all'),
  }

  const categoryList: Array<CatalogNavigationModel> = [parentCatalog, ...rootCategory.catalogs]
  const categoriesCellStyling =
    categoryList.length >= CATALOG_SHRINK_THRESHOLD ? Cell.Styling.Narrow : undefined

  const handleTitleClick =
    ({ id, url, code, catalogs, customUrl }: CatalogNavigationModel) =>
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (customUrl) {
        navigateToPage(customUrl)

        return
      }

      if (catalogs.length && code !== WEB_CATALOG_ROOT_ALL_CODE) {
        event.nativeEvent.stopImmediatePropagation()
        setSelectedCategoryId(id)
      } else {
        navigateToPage(url)
      }
    }

  const renderCategoriesItem = (item: CatalogNavigationModel) => (
    <Cell
      key={item.id}
      type={Cell.Type.Navigating}
      styling={categoriesCellStyling}
      prefix={
        item.code === WEB_CATALOG_ROOT_ALL_CODE ? (
          <Icon name={Dots24} color={Icon.Color.Primary} />
        ) : (
          <Image src={item.photo?.url} scaling={Image.Scaling.Cover} size={Image.Size.Regular} />
        )
      }
      suffix={
        item.badge && <Badge theme={item.badge.theme || 'primary'} content={item.badge.title} />
      }
      body={
        <Text
          as="span"
          truncate
          bold={item.id === selectedCategoryId}
          theme={item.id === selectedCategoryId ? 'amplified' : 'inherit'}
        >
          {item.title}
        </Text>
      }
      url={item.customUrl || item.url}
      urlProps={{ title: item.title }}
      onClick={handleTitleClick(item)}
      testId={`category-item-${item.id}`}
      aria={{ 'aria-label': item.title }}
    />
  )

  const renderCatalogsItem = (item: CatalogNavigationModel) => (
    <CatalogsPanel key={item.id} selectedCategory={item} selectedCategoryId={selectedCategoryId} />
  )

  const renderCategoriesBody = () => (
    <div className="catalog-dropdown__content">
      <div className="u-flexbox">
        <div className="catalog-dropdown__categories">{categoryList.map(renderCategoriesItem)}</div>
        <div className="catalog-dropdown__catalogs">{categoryList.map(renderCatalogsItem)}</div>
      </div>
    </div>
  )

  return (
    <div className="catalog-dropdown">
      <Card styling={Card.Styling.Elevated}>
        <div className="u-overflow-auto">
          <Cell styling={Cell.Styling.Narrow} body={renderCategoriesBody()} />
        </div>
      </Card>
    </div>
  )
}

export default CategoriesPanel
