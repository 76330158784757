import { transformBusinessAccountDto } from 'data/transformers/business-account'
import { transformPhotoDto } from 'data/transformers/photo'

import { UserDto, UserModel } from '../types/user'

/**
 * @deprecated
 */
export const transformUserDto = (dto: UserDto): UserModel => {
  return {
    id: dto.id,
    photo: dto.photo && transformPhotoDto(dto.photo),
    login: dto.login,
    externalId: dto.external_id,
    path: dto.path,
    feedbackReputation: dto.feedback_reputation,
    feedbackCount: dto.feedback_count,
    isFavourite: dto.is_favourite,
    listingRestrictedUntil: dto.listing_restricted_until,
    accountStatus: dto.account_status,
    isHated: dto.is_hated,
    isRestrictedByTerms: !!dto.restricted_by_terms,
    isSoftRestrictedByTerms: !!dto.soft_restricted_by_terms,
    isTermsUpdateAvailable: !!dto.terms_update_available,
    isRestrictedByUnconfirmedRealName: !!dto.restricted_by_unconfirmed_real_name,
    isRestrictedByBalanceActivation: !!dto.restricted_by_balance_activation,
    email: dto.email,
    isBusiness: dto.business,
    businessAccount: dto.business_account && transformBusinessAccountDto(dto.business_account),
  }
}

/**
 * @deprecated
 */
export const transformUserDtos = (users: Array<UserDto>): Array<UserModel> =>
  users.map(transformUserDto)
