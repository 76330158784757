import {
  EscrowFeesDto,
  ItemVerificationFeeEntity,
  BuyerProtectionFeeEntity,
  ShippingFeeEntity,
  ElectronicsVerificationFeeEntity,
} from 'types/dtos/escrow-fees'
import { EscrowFeesModel } from 'types/models/escrow-fees'
import { EscrowFees } from 'constants/escrow-fees'

import { transformCurrencyAmountDto } from './currency-amount'

type Fee =
  | ItemVerificationFeeEntity
  | BuyerProtectionFeeEntity
  | ShippingFeeEntity
  | ElectronicsVerificationFeeEntity

const isFree = (fee: Fee): boolean =>
  !!fee?.final_price?.amount && Number(fee.final_price.amount) === 0

export const transformBuyerProtectionFee = (buyerProtection: BuyerProtectionFeeEntity) => {
  if (!buyerProtection) return null

  return {
    type: EscrowFees.BuyerProtection,
    originalPrice: transformCurrencyAmountDto(buyerProtection.original_price),
    finalPrice: transformCurrencyAmountDto(buyerProtection.final_price),
    discount: transformCurrencyAmountDto(buyerProtection.discount),
    discountPercentage: buyerProtection.discount_percentage,
    isFree: isFree(buyerProtection),
    discountStartDate: buyerProtection.discount_rule?.start_date,
    discountEndDate: buyerProtection.discount_rule?.end_date,
    lowestPrice30Days:
      buyerProtection.lowest_price_30_days &&
      transformCurrencyAmountDto(buyerProtection.lowest_price_30_days),
    showPriceRangeRuleNote:
      !!buyerProtection.discount_rule?.additional_info?.show_price_range_rule_note,
  }
}

export const transformItemVerificationFee = (itemVerification: ItemVerificationFeeEntity) => {
  if (!itemVerification) return null

  return {
    type: EscrowFees.ItemVerification,
    originalPrice: transformCurrencyAmountDto(itemVerification.original_price),
    finalPrice: transformCurrencyAmountDto(itemVerification.final_price),
    discount: transformCurrencyAmountDto(itemVerification.discount),
    discountPercentage: itemVerification.discount_percentage,
    isFree: isFree(itemVerification),
    discountStartDate: itemVerification.discount_rule?.start_date,
    discountEndDate: itemVerification.discount_rule?.end_date,
    lowestPrice30Days:
      itemVerification?.lowest_price_30_days &&
      transformCurrencyAmountDto(itemVerification.lowest_price_30_days),
  }
}

export const transformShippingFee = (shipping?: ShippingFeeEntity) => {
  if (!shipping) return null

  return {
    type: EscrowFees.Shipping,
    originalPrice: transformCurrencyAmountDto(shipping.original_price),
    finalPrice: transformCurrencyAmountDto(shipping.final_price),
    discount: transformCurrencyAmountDto(shipping.discount),
    maxDiscountPercentage: shipping.pricing_rule?.additional_info?.max_discount_percentage,
    discountPercentage: shipping.discount_percentage,
    isFree: isFree(shipping),
  }
}

export const transformElectronicsVerificationFee = (
  electronicsVerification: ElectronicsVerificationFeeEntity,
) => {
  if (!electronicsVerification) return null

  return {
    type: EscrowFees.ElectronicsVerification,
    originalPrice: transformCurrencyAmountDto(electronicsVerification.original_price),
    finalPrice: transformCurrencyAmountDto(electronicsVerification.final_price),
    discount: transformCurrencyAmountDto(electronicsVerification.discount),
    discountPercentage: electronicsVerification.discount_percentage,
    isFree: isFree(electronicsVerification),
    discountStartDate: electronicsVerification.discount_rule?.start_date,
    discountEndDate: electronicsVerification.discount_rule?.end_date,
    lowestPrice30Days:
      electronicsVerification?.lowest_price_30_days &&
      transformCurrencyAmountDto(electronicsVerification.lowest_price_30_days),
  }
}

export const transformEscrowFees = (dto: EscrowFeesDto): EscrowFeesModel => ({
  buyerProtection: transformBuyerProtectionFee(dto[EscrowFees.BuyerProtection]),
  itemVerification: transformItemVerificationFee(dto[EscrowFees.ItemVerification]),
  shipping: transformShippingFee(dto[EscrowFees.Shipping]),
  electronicsVerification: transformElectronicsVerificationFee(
    dto[EscrowFees.ElectronicsVerification],
  ),
})
