/* eslint-disable class-methods-use-this */

import { CookieHandler, CookieData } from '../types/cookie'

export default class SSRCookieManager implements CookieHandler {
  private cookies: Record<string, string>

  constructor(cookies = {}) {
    this.cookies = cookies
  }

  get = (cookie: CookieData) => {
    return this.cookies[cookie.name]
  }

  set = () => {
    throw new Error('Setting cookies is not allowed in SSRCookieManager.')
  }

  delete = (): void => {
    throw new Error('Deleting cookies is not allowed in SSRCookieManager.')
  }
}
