import { Spacer } from '@vinted/web-ui'

import AppButton from './AppButton'

const AppStoreButtons = () => {
  return (
    <div>
      <AppButton platform={AppButton.Platform.Ios} />
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
      <AppButton platform={AppButton.Platform.Android} />
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
    </div>
  )
}

export default AppStoreButtons
