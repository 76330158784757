import { getLocalStorageItem, setLocalStorageItem } from '@marketplace-web/shared/browser'
import { UserDto } from '@marketplace-web/shared/user-data'
import { stringToSha256, toParams } from '@marketplace-web/shared/utils'
import { checkoutBuyerInputEvent } from '_libs/common/event-tracker/events'
import { addressFieldConfig } from 'constants/checkout'
import { GoogleTagManagerEvent } from 'constants/google'
import { Screen } from 'constants/tracking/screens'
import { getUserBuyCount } from 'data/api'
import { googleTagManagerTrack } from 'data/utils/google'
import { CreditCardConfigurationModel } from 'types/models/credit-card-configuration'
import { CurrencyAmountModel } from 'types/models/currency-amount'
import { ShippingPointModel } from 'types/models/shipping-point'
import { CheckoutItemModel } from 'types/models/checkout-item'
import { ShippingOptionModel } from 'types/models/shipping-option'

import { HAS_BOUGHT_STORAGE_VALUE } from './constants'

export const checkIfFirstPurchase = async (userId: number) => {
  if (getLocalStorageItem(`hasBought-${userId}`) === HAS_BOUGHT_STORAGE_VALUE) return false

  const response = await getUserBuyCount()

  if ('errors' in response) return undefined

  return response.value === 1
}

export const trackPurchase = async (
  user: UserDto,
  totalPrice: CurrencyAmountModel,
  items: Array<CheckoutItemModel>,
  transactionId: number | null,
  trackFunction: typeof googleTagManagerTrack,
) => {
  const { id: userId, email: userEmail } = user
  const isFirstPurchase = await checkIfFirstPurchase(userId)
  const formattedEventId = await stringToSha256(transactionId?.toString() || '')

  const event = {
    event_id: formattedEventId,
    user_email: userEmail,
    value: parseFloat(totalPrice.amount),
    currency: totalPrice.currencyCode,
    items: items.map(item => ({
      item_id: item.id,
      value: parseFloat(item.price.amount),
      currency: item.price.currencyCode,
    })),
  }

  trackFunction(GoogleTagManagerEvent.ItemBuy, event)

  if (isFirstPurchase) {
    trackFunction(GoogleTagManagerEvent.FirstBuy, {
      value: parseFloat(totalPrice.amount),
      currency: totalPrice.currencyCode,
      user_email: userEmail,
    })
  }

  setLocalStorageItem(`hasBought-${userId}`, HAS_BOUGHT_STORAGE_VALUE)
}

export const getAddressFieldConfiguration = (transactionId: number | null) => {
  return addressFieldConfig.map(field => {
    return {
      field: field.field,
      required: field.required,
      trackingEvent: checkoutBuyerInputEvent({
        screen: Screen.BillingAddressUpdate,
        target: field.field,
        transactionId,
        valid: true,
      }),
    }
  })
}

export const getShippingOption = (
  shippingOptions: Array<ShippingOptionModel>,
  shippingPoint: ShippingPointModel | null,
) => {
  return shippingOptions.find(
    shippingOption => shippingOption.carrierCode === shippingPoint?.carrierCode,
  )
}

export const getTransactionIdFromUrl = (urlQuery: string) => {
  const { transaction_id } = toParams(urlQuery)

  const transactionId = Number(transaction_id)

  if (Number.isNaN(transactionId)) return null

  return transactionId
}

export const getIsExternalOpenFromUrl = (urlQuery: string) => {
  const { external_open } = toParams(urlQuery)

  return external_open === 'true'
}

export const getCardConfigFromBrand = (
  cardBrand: string,
  cardConfigs: Array<CreditCardConfigurationModel>,
) => {
  return cardConfigs.find(({ name }) => {
    return cardBrand.toLowerCase() === name.toLowerCase()
  })
}
