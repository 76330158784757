import { plug as session } from './session/slice'
import { plug as screen } from './screen/slice'
import { plug as checkout } from './checkout'
import { plug as systemConfiguration } from './system-configuration/slice'

export const staticReducers = {
  ...session,
  ...screen,
}

export const sharedReducers = {
  ...systemConfiguration,
}

// Reducers for core app that are not split by route
export const coreReducers = {
  ...sharedReducers,
  ...checkout,
}
