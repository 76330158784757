import { Card, Cell, Spacer } from '@vinted/web-ui'

import FirstColumn from './FirstColumn'
import SecondColumn from './SecondColumn'

type Props = {
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
}

const AboutPanel = ({ impressumUrl, isBusinessAccountLinksVisible }: Props) => {
  const renderBody = () => (
    <div className="catalog-dropdown__content">
      <div className="u-flexbox u-nowrap">
        <FirstColumn isBusinessAccountLinksVisible={isBusinessAccountLinksVisible} />
        <Spacer />
        <SecondColumn
          impressumUrl={impressumUrl}
          isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
        />
      </div>
    </div>
  )

  return (
    <Card styling={Card.Styling.Elevated}>
      <div className="u-overflow-auto">
        <Cell body={renderBody()} />
      </div>
    </Card>
  )
}

export default AboutPanel
