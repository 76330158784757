import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'

import { UserStatsDto, UserStatsModel } from '../types/user-stats'

export const transformUserStatsDto = (userStats: UserStatsDto): UserStatsModel => ({
  unreadMsgCount: userStats.unread_msg_count,
  unreadPrivateMsgCount: userStats.unread_private_msg_count,
  unreadNotificationCount: userStats.unread_notification_count,
  itemCount: userStats.item_count,
  walletBalance: transformCurrencyAmountDto(userStats.wallet_balance),
  freePushUpsCount: userStats.free_push_ups_count,
  promotedCloset: userStats.promoted_closet,
  promotedClosetEndsInDays: userStats.promoted_closet_ends_in_days,
  canMakeOffers: userStats.can_make_offers,
})
