'use client'

import { useEffect } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import { DebitStatus } from 'constants/payment'
import { CurrencyAmountModel } from 'types/models/currency-amount'
import { EscrowPaymentModel } from 'types/models/payment'
import { CheckoutItemModel } from 'types/models/checkout-item'

import { trackPurchase } from '../../../../../app/pages/Checkout/utils'
import useGoogleTagManagerTrack from './useGoogleTagManagerTrack'

const useGoogleTrackPurchase = ({
  payment,
  orderItems,
  orderPrice,
  transactionId,
}: {
  payment: EscrowPaymentModel | null
  orderItems: Array<CheckoutItemModel>
  orderPrice: CurrencyAmountModel | null
  transactionId: number | null
}) => {
  const { user } = useSession()

  const { googleTagManagerTrack } = useGoogleTagManagerTrack()

  useEffect(() => {
    if (!user || !orderPrice) return
    if (payment?.status !== DebitStatus.Success) return

    trackPurchase(user, orderPrice, orderItems, transactionId, googleTagManagerTrack)
  }, [user, payment, orderPrice, orderItems, transactionId, googleTagManagerTrack])
}

export default useGoogleTrackPurchase
